import { DatePipe } from '@angular/common';

export function DateValueFormatter(data) {
  if(data && data.value){
      const dp = new DatePipe('en-US');
      const s = dp.transform(data.value, 'MM/dd/yyyy');
      return s;
  }
  else{
      return '';
  }
}



export function DateTimeValueFormatter(data) {
  if(data && data.value){
      const dp = new DatePipe('en-US');
      const s = dp.transform(data.value, 'MM/dd/yyyy H:mm:ss');
      return s;
  }
  else{
      return '';
  }
}






