import { Pipe, PipeTransform } from '../../../../node_modules/@angular/core';
import { DatePipe } from '../../../../node_modules/@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
  }
}

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe extends DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    console.log(value);
    if(value) {
      const slashes = value.split('/').length-1;
      // console.log(slashes);
      if(slashes == 1) {
        // only 1 slash exists, assume its in day/month format. Att the current year.
        value = value + '/' + new Date().getFullYear();
        // console.log('full yr: ' + new Date().getFullYear());
        // console.log('val:', value);
      }

      const x: Date = new Date(value);
      if(isNaN(x.valueOf())) {
        value = new Date();
      }
    }

    return super.transform(value, args);
  }
}

@Pipe({
  name: 'ysnConverter'
})
export class YSNConverterPipe implements PipeTransform {

  transform(value: boolean): number {
    if(value == null) {
      return null;
    }
    else {
      if(value) {
        return -1;
      }
      else {return 0; }
    }
  }
}


@Pipe({
  name: 'vCurrency'
}) // currency pipe that validates first.
export class VCurrencyPipe implements PipeTransform {
  transform(value: string): string {
    if(value != null) {
      let asNumber = Number(value);
      const isString = isNaN(asNumber);
      if(isString) {
        asNumber = 0;
      }
      const usdFormat = new Intl.NumberFormat('en-US',{
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return usdFormat.format(asNumber);
    }
    else{
      return null;
    }
  }
}

@Pipe({
  name: 'numeric'
}) // currency pipe that validates first.
export class NumericPipe implements PipeTransform {
  transform(value: string): number {
    if(value != null) {
      let asNumber = Number(value);
      const isString = isNaN(asNumber);
      if(isString) {
        asNumber = 0;
      }
      return asNumber;
    }
    else{
      return null;
    }
  }
}




@Pipe({ name: 'keepHtml', pure: false })
export class EscapeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
  }

  transform(content) {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}
